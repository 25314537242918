import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // parkKey: "zhw", //智慧湾
        parkKey: "tt"  //测试库
    },
    mutations: {
        setParkKey(state, key) {
            state.parkKey = key
        }
    }
})